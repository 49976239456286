/* LifeTimePlan.module.css */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
    text-align: center;
    gap: 12px;
    background-color: #f5f5f5;
    border-radius: var(--mantine-border-radius-md);
    position: relative;
    z-index: 1;
  }
  
  .customButton {
    font-weight: 700;
    letter-spacing: 0.5px;
    border-radius: 5%;
    border-style: solid;
    border-width: 5px;
  }
  